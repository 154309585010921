import React from "react";
import { withApollo, compose } from "react-apollo";
import { connect } from "react-redux";
import { displayNameSelector } from "../selectors/user";
import AppBarDesktop from "./AppBarDesktop";

const Appbar = ({ client, displayName, site, email, landingPageAppSyncClient }) => {
  const id = "simple-popover";
  const logoLetter = displayName?.charAt(displayName.length - 1).toUpperCase() + displayName?.charAt(0).toUpperCase();

  return (
    <AppBarDesktop
      displayName={displayName}
      landingPageAppSyncClient={landingPageAppSyncClient}
      id={id}
      key={id}
      site={site}
      logoLetter={logoLetter}
      email={email}
      client={client}
    />
  );
};

const mapStateToProps = (store) => {
  return ({
    displayName: displayNameSelector(store),
    email: store.user.email,
    site: store.user.site
})};

export default compose(connect(mapStateToProps), withApollo)(Appbar);
