import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import {
  ApplicationGroupSelected,
  ApplicationFavouritesSelected,
  ApplicationCustomGroup,
  AllApplicationsSelected
} from "../features/applications/ApplicationGroup";
import { loadUserInfo as loadUser } from "../features/landingPageUser/redux/actions";
import { getAllGroups, getAllApplications } from "../features/landingPageUser/LandingPageUser";
import { LIST_APPLICATION_GROUPS, LIST_APPLICATIONS } from "../gql/landingapi/queries";
import AdminPanel from "../features/adminPanel/AdminPanel";
import AllCustomGroups from "./AllCustomGroups";
import ApplicationDashboard from "./Dashboard";
import ApplicationSlimCard from "../features/applications/ApplicationSlimCard";
import DashboardPage from "../features/dashboard/DashboardPage";
import EquipmentPage from "../features/equipments/EquipmentPage";
import EquipmentsWrapper from "../features/equipments/equipments-context/EquipmentsWrapper";
import AllWidgetsWrapper from "../features/adminPanel/widgets/context/AllWidgetsWrapper";
import ApplicationAllAppSlimCard from "../features/applications/ApplicationAllAppSlimCard";
import SearchApplicationView from "../features/searchTools/SearchApplicationView";

const ApplicationGroupsView = ({ client, loadUserInfo, selected, name, searchQuery = "" }) => {
  if (searchQuery) {
    return <SearchApplicationView />;
  }
  switch (selected.type) {
    case "adminPanel":
      return <AdminPanel />;
    case "dashboard":
      return (
        <AllWidgetsWrapper>
          <DashboardPage />
        </AllWidgetsWrapper>
      );
    case "allApplications":
      const loadAllApps = async () => {
        const { allGroups } = await getAllGroups({
          client,
          query_groups: LIST_APPLICATION_GROUPS
        });
        const { allApps } = await getAllApplications({
          client,
          allGroups,
          query_apps: LIST_APPLICATIONS
        });
        loadUserInfo({
          allApps
        });
      };
      loadAllApps();
      return (
        <>
          <AllApplicationsSelected name={"All"} Component={ApplicationAllAppSlimCard} />
        </>
      );
    case "myEquipment":
      return (
        <EquipmentsWrapper>
          <EquipmentPage />
        </EquipmentsWrapper>
      );

    case "groups":
      return <ApplicationGroupSelected name={name} />;
    case "customGroup":
      return (
        <ApplicationCustomGroup
          name={name}
          Component={ApplicationSlimCard}
          showBackButton={true}
          groupedType={"quick-link-group"}
        />
      );
    case "favourites":
      return <ApplicationFavouritesSelected name={"Favorites"} Component={ApplicationSlimCard} />;

    case "customGroups":
      return <AllCustomGroups />;

    default:
      const loadAll_Apps = async () => {
        const { allGroups } = await getAllGroups({
          client,
          query_groups: LIST_APPLICATION_GROUPS
        });
        const { allApps } = await getAllApplications({
          client,
          allGroups,
          query_apps: LIST_APPLICATIONS
        });
        loadUserInfo({
          allApps
        });
      };
      loadAll_Apps();
      return <ApplicationDashboard />;
  }
};

const mapStateToProps = (store) => ({
  selected: store.user.selected,
  name: store.user.selected?.name,
  searchQuery: store.searchTools.searchQuery
});

export default compose(
  connect(mapStateToProps, {
    loadUserInfo: loadUser
  }),
  withApollo
)(ApplicationGroupsView);
