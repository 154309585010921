import React, { useCallback, useRef, useState } from "react";
import { signOut } from "../utils/signout";
import SwitchSite from "../features/siteChange/SwitchSite";
import AppSwitcher from "./AppSwitch";
import GRIDIcon from "../icons/GRIDIcon.svg";
import { OwcIcon } from "@one/react/Components/OwcIcon";
import { OwcDoubleGlobalArea } from "@one/react/Components/OwcDoubleGlobalArea";
import { OwcIconButton } from "@one/react/Components/OwcIconButton";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import styles from "./AppBarDesktop.module.scss";
import { Module } from "../constants";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { generateID, NotificationCounter } from "@digitallab/grid-common-components";
import { OwcButton, OwcList, OwcListItem, OwcPopover } from "@one/react";
import { useSelector } from "react-redux";

const AppBarDesktop = ({ displayName, logoLetter, email, client, site, landingPageAppSyncClient }) => {
  const history = useHistory();
  const location = useLocation();
  const userId = useSelector((state) => state.user.user);
  const [userPopup, setUserPopup] = useState(false);
  const popoverElement = useRef(null);
  const siteDropdownOpenRef = useRef(false);
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleClose = () => {
    setUserPopup((prevState) => !prevState);
  };
  const onSiteDropdownChange = useCallback((newValue) => {
    siteDropdownOpenRef.current = newValue;
  }, []);

  const handleMenuChange = (e) => {
    history.push("/notificationsApp");
  };

  const handleNotificationViewAll = () => {
    history.push("/notifications");
  };

  return (
    <>
      <OwcDoubleGlobalArea
        id={generateID.UUID(Module.HEADERS.name, "header", "double_area")}
        data-testid="app-bar-not-auth-main-page"
        style={{
          position: "relative",
          zIndex: 1000,
          filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4))"
        }}
      >
        <div
          slot="primary"
          style={{
            justifyContent: "space-between",
            borderBottom: "none",
            filter: "none"
          }}
          id={generateID.UUID(Module.HEADERS.name, "header", "div")}
        >
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              src={GRIDIcon}
              alt="grid-logo"
              style={{ width: 24, height: 24, marginRight: 15 }}
              id={generateID.UUID(Module.HEADERS.gridIcon, "header", "img")}
            />
            <OwcTypography variant="subtitle1" id={generateID.UUID(Module.HEADERS.gridIconText, "header", "div")}>
              GRID
            </OwcTypography>
            <div className={styles.VerticalLine} id={generateID.UUID(Module.HEADERS.verticalLine, "header", "div")} />
            <OwcTypography variant="subtitle1" id={generateID.UUID(Module.HEADERS.site, "header", "div")}>
              {site}
            </OwcTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.AppBarInfoUserInfo}
              data-testid="app-bar-user-info-user"
              button="true"
              hasFocus={userPopup}
              style={{ marginRight: 12 }}
              id={generateID.UUID(Module.HEADERS.userInfo, "header", "div")}
            >
              <OwcTypography
                variant="caption"
                style={{ marginRight: 10 }}
                id={generateID.UUID(Module.HEADERS.displayName, "header", "div")}
              >
                {displayName}
              </OwcTypography>
              <OwcIcon
                variant="default"
                name="user"
                id="popover-anchor-for-top-bar"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>

            <AppSwitcher />

            <NotificationCounter
              handleNotificationViewAll={handleNotificationViewAll}
              landingPageClient={landingPageAppSyncClient}
              isNotificationHighlighted={location.pathname.indexOf("notification") === 1}
              userId={userId}
            />
            <OwcIconButton
              data-testid="App-bar-user-info-info"
              flat
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
              style={{ marginRight: "12px" }}
              id={generateID.UUID(Module.HEADERS.circleInfo, "header", "button")}
            />
            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
              id={generateID.UUID(Module.HEADERS.rocheLogo, "header", "img")}
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>

      <OwcPopover
        ref={popoverElement}
        anchor="popover-anchor-for-top-bar"
        id="popover-anchor-for-top-bar-popover"
        anchor-position="bottom-start"
        visible={userPopup}
        disable-portal={true}
        onClickOutside={(e) => {
          if (!siteDropdownOpenRef.current) {
            handleClose();
          }
        }}
        style={{ width: 340 }}
      >
        <div className={styles.UserInfoSection} id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_div")}>
          <span
            className={styles.LogoLetter}
            id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_logoletter")}
          >
            {logoLetter}
          </span>
          <OwcTypography
            variant="subtitle1"
            id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_subtitle")}
          >
            {displayName}
          </OwcTypography>
          <OwcTypography variant="body2" id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_body")}>
            {email}
          </OwcTypography>
        </div>

        <OwcList
          data-testid="popover-user-menu-footer-section"
          onSelectChange={handleMenuChange}
          style={{
            borderTop: "thin solid var(--one-color-interaction-default-neutral-2)"
          }}
          id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_list")}
        >
          <OwcListItem
            icon="notification"
            key="notification_key"
            iconType="outlined"
            id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_item")}
          >
            <OwcTypography variant="subtitle1" id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_text")}>
              Notification settings
            </OwcTypography>
          </OwcListItem>
        </OwcList>
        <SwitchSite onSiteDropdownChange={onSiteDropdownChange} />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "16px"
          }}
          id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_switch")}
        >
          <OwcButton
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
            variant="secondary"
            id={generateID.UUID(Module.SUBMENU.name, "app_desktop", "popover_logout")}
          >
            Logout
          </OwcButton>
        </div>
      </OwcPopover>
    </>
  );
};

export default AppBarDesktop;
